<template>
	<div class="registerpanel" style="padding-bottom: 64px;">
		<van-nav-bar title="找回密码" left-text="返回" left-arrow @click-left="onClickLeft" />
		<div style="margin-top:12px;">
			<van-form @submit="onSubmit">
				<van-cell-group inset>
					<van-field v-model="mobnum" label="手机号码" label-align="right" name="mobnum" placeholder="登录手机号" :required="true" />
					<van-field v-model="smscfm" label="短信验证码" label-align="right" name="smscfm" center clearable placeholder="请输入短信验证码" :required="true">
						<template #button>
							<van-button size="small" type="primary" @click="onSendSMS();">发送验证码</van-button>
						</template>
					</van-field>
					<van-field v-model="idencode"  name="idencode" label-align="right" label="图形验证码" placeholder="请输入验证码" :required="true">
						<template #button>
						<ValidCode v-on:getIdentifyCode="getIdentifyCode"></ValidCode>
						</template>
					</van-field>
					<van-field v-model="newpass" type="password" label="新密码" label-align="right" name="newpass" placeholder="新密码" :required="true" />
					<van-field v-model="cfmpass" type="password" label="确认密码" label-align="right" name="cfmpass" placeholder="确认密码" :required="true" />
					<div style="margin: 16px;">
						<van-button round block type="primary" native-type="submit">
							提交
						</van-button>
					</div>
				</van-cell-group>
			</van-form>
		</div>
	</div>
</template>

<script>
	import ValidCode from '../components/ValidCode.vue'
	import {Toast} from 'vant'
	export default {
		name: 'LoginPanel',
		data() {
			return {
				mobnum: '',
				newpass: '',
				cfmpass: '',
				idencode: '',
				idcode : '',
				smscfm: '',
				sessiontoken: '',
			}
		},
		components: {
			//BottomNav,
			ValidCode,
		},
		emits: ['onLoadingData'],
		methods: {
			onClickLeft() {
				this.$router.go(-1);
			},
			getIdentifyCode(idcode) {
				this.idcode = idcode;
				console.log(this.idcode);
			},
			onSubmit(values) {
				if(!this.idencode || this.idencode.toLowerCase() != this.idcode) {
					Toast("请输入正确的图形验证码！");
					return;
				}
				if(!this.mobnum) {
					Toast("请输入正确的手机号码！");
					return;
				}
				if(!this.smscfm) {
					Toast("请输入手机短信验证码！");
					return;
				}
				if(!this.newpass || this.newpass.length < 6) {
					Toast("请输入新密码，新密码长度不能小于6位！");
					return;
				}
				if(this.newpass != this.cfmpass) {
					Toast("两次输入的密码不一致！");
					return;
				}
				
				
				values['sessiontoken'] = this.sessiontoken;
				console.log(values);
				
				this.$emit("onLoadingData", true);
				this.axios.post('api/?entry=user&op=changepass', values).then((response) => {
					this.$emit("onLoadingData", false);
					console.log(response);
					if(response.data.retcode) {
						Toast.fail(response.data.retinfo);
						return;
					}
					
					let that = this;
					Toast.success({message: "密码修改成功", onClose: function(){
						that.$router.push('/login');
					}});
				});
			},
			onSendSMS() {
				if(!this.idencode || this.idencode.toLowerCase() != this.idcode) {
					Toast("请输入正确的图形验证码！");
					return;
				}
				if(!this.mobnum) {
					Toast("请输入正确的手机号码！");
					return;
				}
				
				let params = new Object();
				if(this.sessiontoken)
					params.prevtoken = this.sessiontoken;
				else
					params.prevtoken = '';

				params.mobnum = this.mobnum;
				
				params.smscfm = this.smscfm;
				
				//console.log(params);
				this.$emit("onLoadingData", true);
				this.axios.post('api/frosmscodeident.php', params).then((response) => {
					console.log(response.data);
					let retobj = response.data;
					this.$emit("onLoadingData", false);
					if(retobj.retcode) {
						Toast.fail({message: "获取短信验证码失败，原因：" + retobj.retinfo, onClose: function(){
							console.log('aaa');
							
						}});
						return;
					}
					this.sessiontoken = retobj.sessiontoken;
				});
			}
		}
	}
</script>

<style>
</style>
